export default [
	{
		name: 'facebook',
		url: 'https://www.facebook.com/corneliucirlan/',
		title: 'Connect with me on Facebook'
	},
	{
		name: 'instagram',
		url: 'https://www.instagram.com/corneliucirlan/',
		title: 'Connect with me on Instagram'
	},
	{
		name: 'twitter',
		url: 'https://twitter.com/corneliucirlan',
		title: 'Connect with me on Twitter'
	},
	{
		name: 'linkedin',
		url: 'https://www.linkedin.com/in/corneliucirlan/',
		title: 'Connect with me on LinkedIn'
	},
	{
		name: 'behance',
		url: 'https://www.behance.net/corneliucirlan',
		title: 'Connect with me on Behance'
	},
	{
		name: 'dribbble',
		url: 'https://dribbble.com/corneliucirlan',
		title: 'Connect with me on Dribbble'
	},
	{
		name: 'github',
		url: 'https://github.com/corneliucirlan',
		title: 'Connect with me on Github'
	},
	{
		name: 'mail',
		url: 'mailto:corneliu@corneliucirlan.com',
		title: 'Lets work together'
	}
]
